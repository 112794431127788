import { render, staticRenderFns } from "./ToggleButton.vue?vue&type=template&id=5fab7be3&scoped=true&"
import script from "./ToggleButton.vue?vue&type=script&lang=ts&"
export * from "./ToggleButton.vue?vue&type=script&lang=ts&"
import style0 from "./ToggleButton.vue?vue&type=style&index=0&id=5fab7be3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fab7be3",
  null
  
)

export default component.exports