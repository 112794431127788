import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/rent',
    name: 'Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/sold',
    name: 'Sold',
    component: () => import('../views/Sold.vue'),
  },
  {
    path: '/our-agents',
    name: 'Agents',
    component: () => import('../views/MeetTheTeam.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    component: () => import('../views/ListingDetails.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    component: () => import('../views/AgentProfile.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    component: () => import('../views/AgentProfileListings.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    component: () => import('../views/AgentProfileListingsSold.vue'),
    meta: {
      hide: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue'),
    meta: {
      hide: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
