










import { Listing } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Carousel from './Carousel/Carousel.vue';
import ListingCard from './ListingCard.vue';

@Component({
  components: {
    Carousel,
    ListingCard,
  },
})
export default class ListingCarousel extends Vue {
  @Prop({ required: true })
  private readonly listings!: Listing[];
}
