









import { Component, Vue, Prop } from 'vue-property-decorator';

import { FlexAlignment } from '../../app_code/FlexAlignment';

@Component
export default class SectionTitle extends Vue {
    @Prop()
    private readonly alignment!: FlexAlignment;

    @Prop({ default: 'var(--brand-secondary)' })
    private readonly colour!: string;

    @Prop({ default: false })
    private readonly hasLine!: boolean;
}
